<template>
  <div class="app-container system-home">欢迎登录后台端管理系统</div>
</template>

<script>
export default {};
</script>

<style>
  .system-home {
    padding-top: calc(40vh - 25px) !important;
    font-size: 25px;
    text-align: center;
  }
</style>